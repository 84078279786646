import React, { useEffect, useState } from "react";
import line from "../assets/line.png";
import { BsTwitter } from "react-icons/bs";
import { BiLogoFacebook } from "react-icons/bi";
import { BiLogoTelegram } from "react-icons/bi";
import { BiLogoLinkedin } from "react-icons/bi";
import { RiTwitterXFill } from "react-icons/ri";
import { FaInstagram } from "react-icons/fa";

const Footer = () => {
  const [value,setValue] = useState("");
  const [alertp,setAlert] = useState("");
  const [buttonClicked,setButtonClicked] = useState(false);
  const [success,setIsSuccess] = useState();
  const [showMessage, setShowMessage] = useState(true); 
  const [timeoutId, setTimeoutId] = useState(null);
  // const [isLoading,setIsLoading] = useState(false)
  useEffect(() => {
    return () => {
      // Clear timeout when component unmounts
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const handleSubmit = () => {
    // Clear existing timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setShowMessage(true); 
    setButtonClicked(true);
   
    
    if (value === "") {
      setAlert("Please enter your email!");
      setIsSuccess(false);
    }else if(!value.includes("@")){
      setAlert("Invalid email format!");
      setIsSuccess(false);
    }
     else {
      setAlert("Thanks for subscribing!");
      setIsSuccess(true);
      // setIsLoading(true);
      setValue("")
    }


    
    const id = setTimeout(() => {
      setShowMessage(false);
    }, 3000);

    setTimeoutId(id); // Save the new timeout ID
  };

  return (
    <div className="footer">
      <div className="bg-img">
        <div className="container ">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-lg-6 col-md-12 col-12 p-5 mt-5">
              <h1 className="mb-4 pt-5 mt-3">
                I’d Like to Learn More About VRC Coin
              </h1>
              <img
                src={line}
                className="img-fluid mb-5"
                style={{ height: "5px", width: "60px" }}
              />{" "}
              <br />
              <div
                className="col-lg-12 col-12"
                style={{ position: "relative" }}
              >
                <input
                  type="text"
                  className="w-100 p-3 rounded-pill border outline-0"
                  required
                  placeholder="Enter Your Email"
                  style={{ position: "relative" }}
                  value={value}
                  onChange={(e)=>setValue(e.target.value)}
                />
              
                <div class="outer submit p-0 " type="submit" onClick={handleSubmit}>
                  <div class="button text p-2">Sign up</div>
                </div>
                {buttonClicked && showMessage && (
                <p className={success ? "text-success fw-bold text-end mt-2 mb-0" :  "text-danger fw-bold text-end mt-2 mb-0"}>{alertp}</p>
                )}

                {/* <button className='btn btn-primary bg-launch submit rounded-pill' type="submit" ></button> */}
              </div>
              <div className="d-flex justify-content-center gap-5 p-5 mt-5">
                <a href="https://twitter.com/VrcCoin" target="blank">
                  <div className="icon_clr">
                    {/* <BsTwitter  /> */}
                    <RiTwitterXFill className="text-white fs-4 icon"/>
                  </div>
                </a>

                <a
                  href="https://www.facebook.com/profile.php?id=61553690783612"
                  target="blank"
                >
                  <div className="icon_clr">
                    <BiLogoFacebook className="text-white fs-4 icon" />
                  </div>
                </a>

                <a href="https://t.me/VZSolution" target="blank">
                  <div className="icon_clr">
                    <BiLogoTelegram className="text-white fs-4 icon" />
                  </div>
                </a>
                {/* <a
                  href="https://www.linkedin.com/company/vzonesolution/"
                  target="blank"
                >
                  <div className="icon_clr">
                    <BiLogoLinkedin className="text-white fs-4 icon" />
                  </div>
                </a> */}
                <a
                  href="https://www.instagram.com/vrc.coin/"
                  target="blank"
                >
                  <div className="icon_clr">
                    {/* <BiLogoLinkedin /> */}
                    <FaInstagram className="text-white fs-4 icon" />
                  </div>
                </a>
              </div>
              <h2 className="para mt-4 text-center m-sm-0">
                VRCScan © 2023. All Rights Reserved.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
