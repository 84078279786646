import React from "react";
import lbank from "../assets/lbank-logo.png";
import bitforex from "../assets/bitforex-1.svg";
import { BsArrowUpRight } from "react-icons/bs";
const CoinListing = () => {
  return (
    <div className="container mt-5 mb-5">
     <h1 className="text-dark text-center">Vrc Coin Listing</h1>
      <div className="row d-lg-flex d-block align-items-center mx-auto justify-content-center mt-5 mb-5">
        <div className="d-flex flex-column align-items-center justify-content-center w-50 mbl_wid">
          <img src={lbank} alt="" className="icon_lbank" />
          <a
            href="https://www.lbank.com/en-US/trade/vrc_usdt/"
            target="blank"
            className="text-dark"
          >
            <h5 className="mt-3 lbank">
              Lbank <BsArrowUpRight />
            </h5>
          </a>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center w-50 mbl_wid">
          <img src={bitforex} alt="" className="icon_lbank" />
          <a
            href="https://www.bitforex.com/en/spot/vrc_usdt"
            target="blank"
            className="text-dark"
          >
            <h5 className="mt-3 lbank">
              Bitforex <BsArrowUpRight />
            </h5>
          </a>
        </div>
      </div>
      <h5 className="text-center">More Exchanges Comming Soon.</h5>
    </div>
  );
};

export default CoinListing;
