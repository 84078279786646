import React, { useEffect } from 'react';

function CoinPriceMarqueeWidget() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widgets.coingecko.com/coingecko-coin-price-marquee-widget.js';
    script.async = true;

    // Append the script to the body of the document
    document.body.appendChild(script);

    return () => {
      // Cleanup by removing the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* The widget will be loaded by the script */}
      <coingecko-coin-price-marquee-widget
        coin-ids="virtual-coin,bitcoin,ethereum,binancecoin,polkadot"
        currency="usd"
        background-color="#ffffff"
        locale="en"
      />
    </div>
  );
}

export default CoinPriceMarqueeWidget;